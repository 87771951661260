.works__container{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  row-gap: 2rem;
  margin-bottom: 2rem;
  justify-content: center;
}

.titles{
  margin-top: 5%;
}

.work__grid{
  display: grid;
  align-items: center;
  border: 1px solid rgba(128, 128, 128, 0.507);
  padding: 2rem;
  border-radius: 1.25rem;
  background-color: white;
}

.work__img{
  width: 100%;
  height: 100%;
  border-radius: 1.25rem;
  margin-bottom: 1.5rem;
}

.work__title{
  display: flex;

  justify-content: center;
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  padding: 0.5rem 0
}

.work__buttons{
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  font-size: 14px;
}


/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
  .works__container{
    grid-template-columns: repeat(1, 1fr);
   
  }

  .work__grid{
    padding: 1rem;
  }

  .work__img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0;
  }
  
  .work__title{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
   
  }
  
  .work__buttons{
    display: flex;
    justify-content: center;
   
    grid-template-columns: repeat(2, 1fr);
    margin-top: 0rem;
    font-size: 12px;
  }
  
 
}

/* For medium devices */
@media screen and (max-width: 768px) {
  
}

@media screen and (max-width: 576px) {
}

/* For small devices */
@media screen and (max-width: 350px) {
  
}
