.skills__container{
  grid-template-columns: repeat(2,  675px);
  column-gap: 3rem;
  justify-content: center;
  
}

.skills{
  height: 80%;
  margin-bottom: 5rem;
}

.titles{
  margin-top: 5%;
}

.skills__content{
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2rem 4rem 2rem 4rem;
  border-radius: 1.25rem;
}

.skills__title{
  font-size: 30px;
  font-weight: var(--font-small);
  text-align: center;
  margin-bottom: var(--mb-1-5);
  color: var(--title-color-dark);
}

.skills__box{
  display: flex;
  justify-content: center;
  column-gap: 4.5rem;
}

.skills__group{
  display: grid;
  align-items: flex-start;
  right: 1rem;
}

.skills__data{
  display: flex;
  column-gap: 0.5rem;
  margin-bottom: 1.75rem;
  font-size: var(--normal-font-size);
}

.skills .bx-badge-check{
  font-size: 1.5rem;
  color: var(--title-color);
}

.skills__name{
  font-size: 22px;
  font-weight: var(--font-small);
  line-height: 25px;
}

.skills__level{
  font-size: 18px;
}


/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
  .skills__container{
    grid-template-columns: max-content;
    row-gap: 2rem;
  }
}

/* For medium devices */
@media screen and (max-width: 576px) {
  .skills__container{
    grid-template-columns: 1fr;

  }

  .skills__data{

    column-gap: 0.2rem;
    margin-bottom: 1.25rem;
    font-size: var(--normal-font-size);
  }

  .skills__name{
    font-size: 16px;
    font-weight: var(--font-small);
    line-height: 15px;
  }
  
  .skills__level{
    font-size: 13px;
  }
  
  .skills__box{

    column-gap: 1.5rem;
  }

  .skills__content{
    padding: 1.5rem;
  }

  .skills .bx-badge-check{
    font-size: 1.2rem;
   
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  .skills__box{
    column-gap: 1.25rem
  }

  .skills__name{
    font-size: var(--small-font-size);
  }
}
